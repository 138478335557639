// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: process.env.VUE_APP_NAME, // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: process.env.VUE_APP_LOGO, // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: process.env.VUE_APP_DEFAULT_APP_LAYOUT_RTL,
    skin: process.env.VUE_APP_DEFAULT_APP_LAYOUT_SKIN, // light, dark, bordered, semi-dark
    routerTransition: process.env.VUE_APP_DEFAULT_APP_LAYOUT_ROUTER_TRANSITION, // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: process.env.VUE_APP_DEFAULT_APP_LAYOUT_TYPE, // vertical, horizontal
    contentWidth: process.env.VUE_APP_DEFAULT_APP_LAYOUT_CONTENT_WIDTY, // full, boxed
    menu: {
      hidden: process.env.VUE_APP_DEFAULT_APP_LAYOUT_MENUE_HIDDEN,
      isCollapsed: process.env.VUE_APP_DEFAULT_APP_LAYOUT_MENUE_IS_COLLAPSED,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: process.env.VUE_APP_DEFAULT_APP_LAYOUT_NAVEBAR_TYPE, // static , sticky , floating, hidden
      backgroundColor: process.env.VUE_APP_DEFAULT_APP_LAYOUT_NAVEBAR_BACKGROUND_COLOR, // BS color options [primary, success, etc]
    },
    footer: {
      type: process.env.VUE_APP_DEFAULT_APP_LAYOUT_FOOTER_TYPE, // static, sticky, hidden
    },
    customizer: process.env.VUE_APP_DEFAULT_APP_LAYOUT_CUSTOMIZER,
    enableScrollToTop: process.env.VUE_APP_DEFAULT_APP_LAYOUT_ENABLE_SCROLL_TO_TOP,
  },
}
