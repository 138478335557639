export default {
  namespaced: true,
  state: {
    user: {},
    permissions: null,
  },
  getters: {
    getPermissions: state => state.permissions,
  },
  mutations: {
    LOGIN(state, payload) {
      state.user = payload
    },
    LOGOUT(state) {
      state.user = {}
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    REMOVE_PERMISSIONS(state) {
      state.permissions = null
    },
  },
  actions: {},
}
