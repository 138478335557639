import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'ً

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import cart from './cart'
import auth from './auth'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    app,
    appConfig,
    verticalMenu,
    cart,
  },
  strict: process.env.DEV,
  // plugins: [vuexLocal.plugin],
})
